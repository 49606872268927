
.icons-wrapper {
  padding: 25vh 0 10vh;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
	@media (max-width: 575.89px) {
		padding: 15vh 0 10vh;
	}
	.symbol {
		svg {
			width: 25px;
			height: 25px;
			padding: 0;
			fill: none;
			stroke: currentColor;
			stroke-width: 2px;
			&:last-of-type {
				margin-right: 2rem;
				@media (max-width: 991.89px) {
					margin-right: 0;
				}
			}
		}
		svg.mousedown {
			fill: currentColor;
		}
	}
}
