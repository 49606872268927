
/* write styles for .shuffle-text-char with higher specificity */

.my-shuffle {
 // outline: 1px solid red;
}

.my-shuffle .shuffle-text-char {
  animation: 0.1s ease-in-out both; /* none for no effect */
}

@keyframes myWordEnter {
  0% {
    opacity: 0;
//    transform: translate(-100%, 0) scale(1);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
}
