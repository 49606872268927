@font-face {
  font-family: "Ubuntu Mono";
  src: url("../res/fonts/UbuntuMono-Regular.ttf") format("truetype");
  font-weight: 400;
}

html {
  @media (max-width: 575.89px) {
    font-size: .85rem;
  }
}

* {
  box-sizing: border-box;
//  outline: 1px solid red;
}

body {
  height: 100%;
  margin: 0;
  color: #000;
  background-color: #fff;
  transition: all .5s ease;
  font-family: 'Ubuntu Mono', monospace;
  font-size: 1.2rem;
  -moz-osx-font-smoothing: grayscale;
   -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
  color: currentColor;
}

h1 {
  margin: 0;
  font-size: 3.2rem;
  font-weight: 700;
}

p {
  margin: 0 0 1rem 0;
  line-height: 1.35;
  @media (max-width: 575.89px) {
    line-height: 1.4;
  }
  a {
    //text-decoration: underline 2px dotted currentColor;
    text-decoration-line: underline;
    text-decoration-thickness: 2px;
    text-decoration-style: dotted;
    text-decoration-color: currentColor;
    text-underline-offset: 2px;
  }
}

@mixin dark-mode {
  color: #eee;
  background-color: #222;
}

@mixin light-mode {
  color: #000;
  background-color: #fff;
}

@media (prefers-color-scheme: dark) {
  body {
    @include dark-mode;
  }
  body.inverted {
    @include light-mode;
  }
}

@media (prefers-color-scheme: light) {
  body {
    @include light-mode;
  }
  body.inverted {
    @include dark-mode;
  }
}

.visually-hidden {
  height: auto;
  width: 1px;
  padding: 0;
  margin: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
}

.swap-button {
  padding-top: .5rem;
}

.page-wrapper {
  max-width: 60vw;
  min-height: 100vh;
  margin: 0 4rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 991.89px) {
    max-width: initial;
  }
  @media (max-width: 767.89px) {
    margin: 0 1rem;
  }

}

.icons-wrapper {
  padding: 25vh 0 10vh;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  @media (max-width: 575.89px) {
    padding: 15vh 0 10vh;
  }
  .my-svg {
    width: 25px;
    height: 25px;
    padding: 0;
    fill: none;
    stroke: currentColor;
    stroke-width: 2px;
    &:last-of-type {
      margin-right: 2rem;
      @media (max-width: 991.89px) {
        margin-right: 0;
      }
    }
  }
  .mousedown {
    fill: currentColor;
  }
}

.content-wrapper {
}


.content,
.footer-wrapper {
  display: none;
  &.show {
    display: block;
  }
}

.footer-wrapper {
  padding: 1rem 0 .5rem;
  margin-top: auto;
  p {
    font-size: .9rem;
    margin: 0;
  }
}

.tooltip {
  position: relative;
  // display: inline-block;
  @media (max-width: 575.89px) {
    position: initial;
  }
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  border: 2px solid black;
  background-color: black;
  color: #fff;
  text-align: center;
  opacity: 0;
  transition: opacity .5s;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  bottom: 0;
  z-index: 1;
  @media (max-width: 575.89px) {
    left: 1rem;
    bottom: initial;
    transform: translateY(1.5rem);
  }
}

.tooltip img {
  max-width: 25vw;
  height: auto;
  display: block;
  @media (max-width: 575.89px) {
    max-width: calc(100vw - 2rem);
  }
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 575.89px) {
  .tooltip:focus .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}

.external-link {
  white-space: nowrap;
}

.external-link:after {
  content: '';
  height: .9rem;
  width: .9rem;
  margin-left: 5px;
  display: inline-block;
  transform: translateY(1px);
  -webkit-mask: url('../res/img/external-link.svg');
          mask: url('../res/img/external-link.svg');
  -webkit-mask-size: cover;
          mask-size: cover;
  background-color: currentColor;
}

.tooltiptext.external-link:after {
  position: absolute;
  bottom: 5px;
  right: 4px;
  color: #000;
}
