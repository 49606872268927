
.menu {
	padding: 0;
	margin: 1.5rem 0;
 	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.menu-item {
		margin: .5rem 0;
		display: inline-block;
		@media (max-width: 575.89px) {
			/* flex: 0 0 50%;*/
		}
		margin: 0 3rem 0 0;
		font-weight: 700;
		&.active {
			text-decoration: underline 3px solid red;
			text-decoration-line: underline;
			text-decoration-thickness: 3px;
			text-decoration-style: solid;
			text-decoration-color: red;
			text-underline-offset: 4px;
		}
		@media (max-width: 575.89px) {
			display: inline-block;
			margin-right: 1.5rem;
			margin-bottom: 0;
		}
	}
}
